import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';


const headerColor = '#f9bf29';

const AuslotsPage = ({ data }) => (
  <>
    <SEO
      title="Top Neosurf Bonus Code - $500 &amp; 125 Free Spins - AUSlots"
      description="Find the best Neosurf Bonus Code online. Get up to $500 bonus and 125 Free Spins at Awesome &amp; Unique Slots - AUSlots.com"
      alternateLanguagePages={[
        {
          path: 'casino/auslots',
          language: 'en'
        },
        {
          path: 'casino/auslots',
          language: 'fr'
        },
        {
          path: 'casino/auslots',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="AUSlots delivers Awesome &amp; Unique Slots" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Exclusive &amp; Top-rated Neosurf Bonus Code at AUSlots.com</h1>
            <p className="textColorLight">Awesome &amp; Unique Slots at AUSlots.com!</p>
            <p className="textColorDark">AUSlots brings you top-notch selection of over 1000 online slots &amp; casino games. From the latest slot machines with superb 3D graphics to the all-time classic slots. Prefer Table Games? AUSlots delivers both animated and live casino games streamed in real-time 24/7 via HD cameras. Are you into Video Poker Games? Or maybe Virtual Sports? <strong>Just name it, AUSlots Casino has it!</strong> Furthermore, AUSlots players are regularly treated with generous bonus offers including wager-free Free Spins, Cashbacks &amp; Exclusive Bonuses! If you are looking for the specific exclusive Neosurf Bonus Code, you are at the right place. Find out more information below.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Neosurf Bonus Code Info</h2>
            <ul>
              <li>125% up to 500$/&euro; &amp; 125 <strong>wager-free Free Spins</strong></li>
              <li>Free Spins are on Dragon Kings, Spinfinity Man or Spinions Beach Party (the slot you first open from the offered ones)</li>
              <li>Use the <strong>code: NEO125+125</strong></li>
              <li>Minimum deposit is 20$/&euro;</li>
              <li>Rollover is times thirty (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">TO AUSLOTS CASINO</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="NEO125+125 Neosurf Bonus Code" />
        </div>
      </div>
    </div>
  </>
)

export default AuslotsPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/auslots/AUSlots-delivers-Awesome-&-Unique-Slots.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/auslots/NEO125+125-Neosurf-Bonus-Code.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
